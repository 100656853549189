<template>
  <FormModal
    show-back-btn
    title="¿Qué tipo de aislamiento existe en la vivienda?"
  >
    <template #body>
      <TypeOfInsulationFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfInsulationFormStep from '~/components/form/steps/typeOfInsulation/TypeOfInsulationFormStep'

export default {
  components: {
    TypeOfInsulationFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Tipo de aislamiento',
      headDescription:
        'Indique qué tipo de instalación de bomba de calor está buscando.',
      path: '/solicitudes-de-cotizacion/tipo-de-aislamiento',
      progressValue: 30,
      checkoutStep: 2,
    }
  },
}
</script>
