<template>
  <FormModal
    show-back-btn
    title="¿Qué tipo de calefacción quieres conectar a la bomba de calor?"
  >
    <template #body>
      <TypeOfHeatingFormStep :progress-value="progressValue" />
    </template>
  </FormModal>
</template>

<script>
import FormModal from 'chimera/all/themes/blueflow/components/layout/FormModal'
import AbstractFormPage from 'chimera/all/components/page/AbstractFormPage'
import TypeOfHeatingFormStep from '~/components/form/steps/typeOfHeating/TypeOfHeatingFormStep'

export default {
  components: {
    TypeOfHeatingFormStep,
    FormModal,
  },

  extends: AbstractFormPage,

  layout: 'form',

  /**
   * @returns {object}
   */
  data() {
    return {
      headTitle: 'Tipo de calefacción',
      headDescription:
        'Indique qué tipo de instalación de bomba de calor está buscando.',
      path: '/solicitudes-de-cotizacion/tipo-de-calefaccion',
      progressValue: 45,
      checkoutStep: 3,
    }
  },
}
</script>
